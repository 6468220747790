@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'Linik Sans';
    src: url('../public/fonts/LinikSans-Bold.eot');
    src: url('../public/fonts/LinikSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/LinikSans-Medium.woff2') format('woff2'),
        url('../public/fonts/LinikSans-Medium.woff') format('woff'),
        url('../public/fonts/LinikSans-Medium.ttf') format('truetype'),
        url('../public/fonts/LinikSans-Medium.svg#LinikSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Linik Sans';
    src: url('../public/fonts/LinikSans-Medium_1.eot');
    src: url('../public/fonts/LinikSans-Medium_1.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/LinikSans-Medium_1.woff2') format('woff2'),
        url('../public/fonts/LinikSans-Medium_1.woff') format('woff'),
        url('../public/fonts/LinikSans-Medium_1.ttf') format('truetype'),
        url('../public/fonts/LinikSans-Medium_1.svg#LinikSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Linik Sans';
    src: url('../public/fonts/LinikSans-Regular.eot');
    src: url('../public/fonts/LinikSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/LinikSans-Regular.woff2') format('woff2'),
        url('../public/fonts/LinikSans-Regular.woff') format('woff'),
        url('../public/fonts/LinikSans-Regular.ttf') format('truetype'),
        url('../public/fonts/LinikSans-Regular.svg#LinikSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Linik Sans';
    src: url('../public/fonts/LinikSans-SemiBold.eot');
    src: url('../public/fonts/LinikSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/LinikSans-SemiBold.woff2') format('woff2'),
        url('../public/fonts/LinikSans-SemiBold.woff') format('woff'),
        url('../public/fonts/LinikSans-SemiBold.ttf') format('truetype'),
        url('../public/fonts/LinikSans-SemiBold.svg#LinikSans-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Linik Sans';
    src: url('../public/fonts/LinikSans-Bold.eot');
    src: url('../public/fonts/LinikSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/LinikSans-Bold.woff2') format('woff2'),
        url('../public/fonts/LinikSans-Bold.woff') format('woff'),
        url('../public/fonts/LinikSans-Bold.ttf') format('truetype'),
        url('../public/fonts/LinikSans-Bold.svg#LinikSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Linik Sans';
    src: url('../public/fonts/LinikSans-Bold_1.eot');
    src: url('../public/fonts/LinikSans-Bold_1.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/LinikSans-Bold_1.woff2') format('woff2'),
        url('../public/fonts/LinikSans-Bold_1.woff') format('woff'),
        url('../public/fonts/LinikSans-Bold_1.ttf') format('truetype'),
        url('../public/fonts/LinikSans-Bold_1.svg#LinikSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Linik Sans';
    src: url('../public/fonts/LinikSans-SemiBold_1.eot');
    src: url('../public/fonts/LinikSans-SemiBold_1.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/LinikSans-SemiBold_1.woff2') format('woff2'),
        url('../public/fonts/LinikSans-SemiBold_1.woff') format('woff'),
        url('../public/fonts/LinikSans-SemiBold_1.ttf') format('truetype'),
        url('../public/fonts/LinikSans-SemiBold_1.svg#LinikSans-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Linik Sans';
    src: url('../public/fonts/LinikSans-Regular_1.eot');
    src: url('../public/fonts/LinikSans-Regular_1.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/LinikSans-Regular_1.woff2') format('woff2'),
        url('../public/fonts/LinikSans-Regular_1.woff') format('woff'),
        url('../public/fonts/LinikSans-Regular_1.ttf') format('truetype'),
        url('../public/fonts/LinikSans-Regular_1.svg#LinikSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Linik Sans';
    src: url('../public/fonts/LinikSans-Light.eot');
    src: url('../public/fonts/LinikSans-Light.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/LinikSans-Light.woff2') format('woff2'),
        url('../public/fonts/LinikSans-Light.woff') format('woff'),
        url('../public/fonts/LinikSans-Light.ttf') format('truetype'),
        url('../public/fonts/LinikSans-Light.svg#LinikSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Linik Sans';
    src: url('../public/fonts/LinikSans-Light_1.eot');
    src: url('../public/fonts/LinikSans-Light_1.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/LinikSans-Light_1.woff2') format('woff2'),
        url('../public/fonts/LinikSans-Light_1.woff') format('woff'),
        url('../public/fonts/LinikSans-Light_1.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Moneta';
    src: url('../public/fonts/Moneta-Regular.eot');
    src: url('../public/fonts/Moneta-Regular.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/Moneta-Regular.woff2') format('woff2'),
        url('../public/fonts/Moneta-Regular.woff') format('woff'),
        url('../public/fonts/Moneta-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

