/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #0a0a0a;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.css-1jpqtnc {
  font-size: 20px;
  line-height: 38px;
  font-weight: 600;
}

.search-btn {
  left: auto !important;
  right: 0;
  background: #0f1011;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

.css-1n46nf3 {
  color: #ffffff !important;
}

.search-input{ padding: 10px !important; } 
select.css-161pkch,
.css-qluo93{background: #fff !important;}
.css-spn4bz,
.css-13fflh2 {
  line-height: 40px;
  font-weight: 600;
}

.css-n7wbdx {
  padding: 5px 10px 4px 10px;
  height: 28px !important;
  text-transform: uppercase;
}

.css-ilx5iv {gap: 2px !important;}
.css-a5mhaz{padding: 0 !important;}
.main-tabs {
  position: sticky;
  top:60px;
  background: #fff;
  padding: 10px 0;
}

.main-tabs .css-6z8agw {
  background: transparent;
  border: none;
  border-top: 2px solid transparent;
  padding: 0 0px;
  height: 35px;
  border-radius:0px;
  margin: 0 20px 0 0;
  color: #000;
  font-weight: 500;
}

.main-tabs .css-6z8agw:hover,
.main-tabs .css-6z8agw[aria-selected=true], 
.main-tabs .css-6z8agw[data-selected] {
  color: #4299e1;
  border-top: 2px solid #000;
  background: transparent;
}

.main-tabs .css-6z8agw.search-tab{border: none; color: #000; font-weight: 600; text-transform: lowercase;}
.main-tabs .css-6z8agw.search-tab .css-1tnq7dy{color: #000;}
.main-tabs .css-6z8agw.search-tab:hover,
.main-tabs .css-6z8agw.search-tab[aria-selected=true], 
.main-tabs .css-6z8agw.search-tab[data-selected],
.main-tabs .css-6z8agw.search-tab:hover .css-1tnq7dy,
.main-tabs .css-6z8agw.search-tab[aria-selected=true] .css-1tnq7dy, 
.main-tabs .css-6z8agw.search-tab[data-selected] .css-1tnq7dy{
  color:#000;
  background-color:transparent;
}

.main-tabs .chakra-button{
  background: transparent;
  border: 1px solid #000;
  padding: 0 10px;
  height: 35px;
  border-radius: 4px;
  margin: 0 4px 0 0;
  color: #4299e1;
  font-weight: 500;
}

.main-tabs .chakra-button .chakra-icon{ width: 14px; }
.main-tabs .chakra-button:hover{
  background-color:#000;
  color: #fff;
}

.chakra-button {
  background-color: #e2e8f0;
  border-color: #e2e8f0;
}
.carousel .thumbs-wrapper {
  margin: 0 0px 10px 0 !important;
}

.static-map{width: auto; height: 150px;position: relative;overflow: hidden;}
.colum70{ width:70%; }
.colum30{ width:30%; }
.overview-sec .chakra-divider{ padding:1% 0 2% 0; }
.compare-prdt-container{
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
}
.compare-prdt-list{
  display: flex;
  flex: 0 0 auto;
}
.compare-prdt-list .chakra-divider{ padding: 0 !important; margin: 0 !important; }
.compare-prdt-list .css-mo5lkh {margin-top: 0 !important; margin-bottom: 0 !important;}
.compare-prdt-list.hovercompare-prt-list .css-mo5lkh:hover{ background-color: #f2f2f2; }

.css-bhb3xr svg{color:#4299e1;}

.map-sec{ position: relative; overflow: hidden; height: 500px; }
.salestab-sec .chakra-tabs__tablist{ padding-bottom:10px; }
.salestab-sec .css-6z8agw{
  color: #53a2e4;
  border:1px solid #53a2e4;
  border-radius: 50px; margin-right: 10px; }
.salestab-sec .css-6z8agw[aria-selected=true], 
.salestab-sec .css-6z8agw[data-selected] {
  --tabs-color: var(--chakra-colors-teal-600);
  --tabs-border-color: var(--chakra-colors-white);
  border-color: inherit;
  border-bottom-color: var(--tabs-border-color);
  color: #ffffff;
  background: #53a2e4;
  
}

.schooldata-sec .chakra-card{
  background: #f9fafb;
}
.schooldata-sec .chakra-card button.chakra-button {    
  background-color: #3182ce !important;
  color: #fff !important; border:1px solid #3182ce
}

.schooldata-sec .chakra-card button.chakra-button:hover {   color: #4299e1 !important;
  background-color: #fff !important;
}


.thinkblock{position: sticky;
  top: 62px;
  z-index: 2;
  background: #fff;}
  
table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  overflow: hidden;
}
table td, table th {
  border-top: 1px solid #ECF0F1;
  padding: 15px;
}
table td {
  border-left: 1px solid #ECF0F1;
  border-right: 1px solid #ECF0F1;
  padding:15px !important;
}
table th {
  background-color: #fafbfc;
  color: #060606;
}
table tr:nth-of-type(even) td {
  background-color: #fafbfc;
}

.banner-btn {
  background: #fff;
  border: 1px solid #fff;
  padding:5px 10px;
  font-size: 14px;
  border-radius: 4px;
  text-transform: uppercase;
  color: #4299e1;
  font-weight:600;
  transition: background-color 0.3s, color 0.3s; /* Add transition for smooth hover effect */
}

.banner-btn:hover {
  background: #4299e1; border: 1px solid #4299e1;
  color: #fff;
}



.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Shadow effect */
}

.popup-content h2 {
  margin-top: 0;
}

.popup-content p {
  margin-bottom: 10px;
}

.popup-content button.close {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 3px;
  cursor: pointer;
  position: fixed;
  right: 0;
  top: 0;
}

.popup-content button.close:hover {
  background-color: #0056b3;
}

.filter-autocomplete-address-search{
  background-color: #fff !important;
  border: 1px solid #9ba8b0 !important;
  padding: 10px 0;
}

.filter-sec h3{ color: #0056b3;
  font-weight: 600;
  display: block;
  margin: 10px 0 4px 0;}
  .filter-sec h3:first-child{margin:0px 0 4px 0;}
.filter-search{ margin: 0 0 0 0 !important;   overflow: hidden; }
.filter-search form{overflow: hidden !important; border-radius:4px; }
.filter-search .ais-SearchBox-input{
  padding: 0 15px !important;
  box-shadow: none;
  border-radius:4px;
  overflow: hidden;
  border: 1px solid #9ba8b0;
}
.filter-search .ais-SearchBox {margin: 0 0 0 0 !important;   overflow: hidden;}
.filter-sec .search-input {padding:0px !important;}
.filter-sec .ais-RefinementList-searchBox .ais-SearchBox-form {
  height: 40px;
}


.filter-sec .ais-RefinementList-item .ais-RefinementList-label{
  display: flex;
  padding:8px 0; /* Adjust padding as needed */
}

.filter-sec .ais-RefinementList-labelText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 78%;
  display: inline-block;
  line-height: 14px;
}

/* Style for the count */
.filter-sec .ais-RefinementList-count {
  margin-left: 10px;
  /* background: #4299e1; */
  /* color: #fff; */
  border: 0px solid #4299e1;
}

.filter-sec .ais-RangeInput-form .ais-RangeInput-input{    
  box-shadow: none;
  border-radius:4px;
  border: 1px solid #9ba8b0;
  padding: 0 10px !important;
  text-align: center;}

.filter-sec .ais-RangeInput-form .ais-RangeInput-submit{
  left: auto !important;
  right: 0;
  background: #fafbfc;
  color: #000;
  border-radius:4px;
  cursor: pointer;
  text-transform: uppercase;
  border: none;}

  .filter-sec .datepicker-sec input[type="date"] {
    color: #bfbfbf;
    display: inline-block;
    width: 48%;
    margin-left: 4%;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
    border: 1px solid #9ba8b0;
}
.filter-sec .datepicker-sec input[type="date"]:first-child{
  margin-left:0%;
}

.search-list-sec ul li.ais-Hits-item {
  width: 100%;
  border-radius: 8px !important;
  box-shadow: 0 3px 6px rgba(25,32,36,0.16), 0 -1px 4px rgba(25,32,36,0.04);
  padding: 10px;
  align-items: start;
  display: block !important;
  margin: 10px 0 !important;
}
.search-list-sec ul li.ais-Hits-item h2 {
  font-weight: 600;
  margin-bottom: 2px;
}

.search-list-sec ul li.ais-Hits-item h2 span{     font-weight: 600;
  font-size: 22px;
  line-height: 30px;}

  .search-list-view-sec ul.ais-Hits-list {
    margin-top: -1rem;
    margin-left: -1rem;
  }
  .search-list-view-sec ul {
    display: flex;
    flex-wrap: wrap;
    gap: 3px; 
  }

  .search-list-view-sec ul li.ais-Hits-item {
    flex: 1 1 calc(50% - 16px);
    max-width: calc(50% - 16px);
    border-radius: 8px !important;
    box-shadow: 0 3px 6px rgba(25, 32, 36, 0.16), 0 -1px 4px rgba(25, 32, 36, 0.04);
    align-items: start;
    display: block !important;
  }

  .search-list-view-sec ul li.ais-Hits-item h2 {
    font-weight: 600;
    margin-bottom: 2px;
  }
  
  .search-list-view-sec ul li.ais-Hits-item h2 span{     
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;}
  
.searchRatingbox{
  cursor: pointer;
  margin: 5px;
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: 3px;
  border: 1px solid #ccc;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
}
.searchRatingbox:hover, .searchRatingbox.active{
  background-color: #e6ebef;
}
  .Community-property-item{
    width: 100%;
    border-radius: 8px !important;
    box-shadow: 0 3px 6px rgba(25,32,36,0.16), 0 -1px 4px rgba(25,32,36,0.04);
    padding: 10px;
    align-items: start;
    display: block !important;
    margin: 10px 0 !important;
  }
  .Community-property-item {
    font-weight: 600;
    margin-bottom: 2px;
  }
  
  .Community-property-item h2 span{     font-weight: 600;
    font-size: 22px;
    line-height: 30px;}
    
.hero-sec {
  padding: 12% 8% 4% 8% !important; 
  background-size: cover !important;
  background-position: bottom center !important;
}
.hero-sec h1.brandText{ 
font-size: 40px;
width: 50%;
line-height: normal;
margin-bottom: 30px;
}

.hero-sec .chakra-tabs .chakra-tabs__tablist{ border:none; }
.hero-sec .chakra-tabs .chakra-tabs__tablist .chakra-tabs__tab {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
}
.hero-sec .chakra-tabs .chakra-tabs__tablist .chakra-tabs__tab {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 5px 15px;
  margin-right: 10px;
}

.hero-sec .chakra-tabs .chakra-tabs__tablist .chakra-tabs__tab[aria-selected=true], 
.hero-sec .chakra-tabs .chakra-tabs__tablist .chakra-tabs__tab[data-selected],
.hero-sec .chakra-tabs .chakra-tabs__tablist .chakra-tabs__tab:hover {
  background: #4299e1;
  color: #fff !important;
  /* border: none; */
  border-radius: 50px;
}
.hero-sec input.chakra-input.css-1cjy4zv {
  /* color: #fff !important; */
  background: #fff;
  margin: 5px 0 10px 0;
  border-radius: 50px;
}

.hero-sec  div#homepageTabContainer {
  width: 50%;
}
.hero-sec .css-rltemf{text-align: right;}
.hero-sec button.chakra-button.css-1ycmff7 {
  
  display: inline-block;
  border-radius: 50px;
  margin: 0 0 50px 0;
}


.ais-Stats {
  background: #ffffff;
  padding: 10px;
  margin: 5px 0;
  border-radius: 8px;
}
span.ais-CurrentRefinements-label{ font-weight: 600; color: #0056b3;}
.ais-CurrentRefinements-item{ margin: 0 0 5px 0 !important; background-color:transparent !important; border: 0px solid #fff !important; }
.ais-CurrentRefinements-category {
  display: inline-block !important;
  margin-left: 0.5rem;
  background: #ebf5ff;
  padding: 0 6px;
  border-radius: 5px;
}
.ais-CurrentRefinements-category {
  display: inline-block;
  margin-left: 0.5rem;
  margin: 0 8px 6px 0px;
}
.ais-CurrentRefinements-list,
.ais-CurrentRefinements-item,
.ais-CurrentRefinements {
  display: block !important;
}
.activeHeader{
  border-bottom: 2px solid #000;
  font-weight: bold;

}



@media screen and (max-width: 767px) {
.css-or03j9 {display: block !important;}
.css-13yncan{ display: inline-block !important; vertical-align: top; text-align: left;}
.css-1ef8uzr{ display: inline-block !important; vertical-align: top; text-align: left; width:90%;}
.css-166wn1o {
  text-align: left;
  font-weight: 600;
  padding: 10px 0 0 0;
  float: left;
}
.css-1kpu26l {
  margin-left: 0;
  margin-right: 0;
  margin-top: 40px;
  display: block;
}
.css-lvyu5j {
  float: right;
  position: absolute;
  right: 10px;
  top: 6px;
}
.main-tabs{display: block;}
.main-tabs .css-6z8agw {margin: 0 20px 10px 0; display: inline-block;}
.mobile-block,
.css-1dh8zy8 { display: block !important; }
.css-1dh8zy8 .css-cfm5gl{margin-bottom: 30px;}

}