.testimonial-page .heading {
  padding: 1rem 0;
  font-size: 3.5rem;
  text-align: center;
}

.testimonial-page .swiper_container {
  position: relative;
  height: 500px;
}

.testimonial-page .swiper-slide {
  width: 20rem;
  height: 16rem;
  position: relative;
}

.testimonial-page .swiper-slide-next {
  filter: blur(2px);
}
.testimonial-page .swiper-slide-prev {
  filter: blur(2px);
}

.testimonial-page .swiper-slide img {
  width: 26rem;
  height: 22rem;
  border-radius: 2rem;
  object-fit: cover;
  margin: 0 auto;
}

.testimonial-page .swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.testimonial-page .slider-controler {
  position: relative;
  bottom: 14rem;
  left: 19%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-page .slider-controler .swiper-button-next {
  left: 53.7% !important;
  transform: translateX(-37%) !important;
}

.testimonial-page .swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, 49px);
  right: auto;
}

.testimonial-page .slider-controler .slider-arrow {
  height: 60px;
  width: 20px;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid rgb(219, 219, 219);
}

.testimonial-page .slider-controler .slider-arrow svg {
  width: 15px !important;
  height: 15px !important;
}

.testimonial-page .slider-controler .slider-arrow::after {
  content: "";
}

@media only screen and (min-width: 730px) and (max-width: 980px) {
  .testimonial-page .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, 45px) !important;
    right: auto;
  }

  .testimonial-page .slider-controler .swiper-button-next {
    left: 53.9% !important;
    transform: translateX(-58%) !important;
  }
}
@media only screen and (min-width: 1100px) and (max-width: 1380px) {
  .testimonial-page .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, 6px) !important;
    right: auto;
  }


  .testimonial-page .slider-controler {
    position: relative;
    bottom: 18rem;
    left: 19%;
    display: flex;
    align-items: center;
    justify-content: center;
}

  .testimonial-page .slider-controler .swiper-button-next {
    left: 59% !important;
    transform: translateX(-58%) !important;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1100px) {
  .testimonial-page .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, -12px) !important;
    right: auto;
  }

  .testimonial-page .slider-controler {
    position: relative;
    bottom: 18rem;
    left: 19%;
    display: flex;
    align-items: center;
    justify-content: center;
}

  .testimonial-page .slider-controler .swiper-button-next {
    left: 62.9% !important;
    transform: translateX(-58%) !important;
  }
}
@media only screen and (min-width: 728px) and (max-width: 980px) {
  .testimonial-page .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, 57px) !important;
    right: auto;
  }

  .testimonial-page .slider-controler {
    position: relative;
    bottom: 19rem;
    left: 19%;
    display: flex;
    align-items: center;
    justify-content: center;
}

  .testimonial-page .slider-controler .swiper-button-next {
    left: 53.9% !important;
    transform: translateX(-96%) !important;
  }
}
@media only screen and (min-width: 480px) and (max-width: 728px) {
  .testimonial-page .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, 5px) !important;
    right: auto;
  }

  .testimonial-page .slider-controler .swiper-button-next {
    left: 53.9% !important;
    transform: translateX(86%) !important;
  }

  .testimonial-page .slider-controler {
    position: relative;
    bottom: 19rem;
    left: 19%;
    display: flex;
    align-items: center;
    justify-content: center;
}
}
@media only screen and (min-width: 375px) and (max-width: 480px) {
  .testimonial-page .swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, -34px) !important;
    right: auto;
}

.testimonial-page .slider-controler {
  position: relative;
  bottom: 19rem;
  left: 19%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonial-page .slider-controler .swiper-button-next {
  left: 60.9% !important;
  transform: translateX(86%) !important;
}
}
