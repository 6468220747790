/* Base */

* {
  margin: 0;
  padding: 0;
}

:root {
  --white: #fff;
  --black: #000;
  --blue: #072B7D;
  --light-blue:#006aad;
  --light-gry:#e6ebef;
  --font-primary: 'Canela Deck';
  --font-secondry:'Poppins', sans-serif;
}
html {
  scroll-behavior: smooth;
  font-size: 90%;
}
body {
  font: var(--font-p);
  color: var(--black);
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
}
a {
  text-decoration: none;
}
a:hover,
a:focus {
  outline: none;
}

.btn-blue{    
  line-height: normal;
  letter-spacing: .025rem;
  margin: 10px 0 0 0;
  font-size: 14px;
  border-radius: 5px;
  padding: 12px 24px;
  position: relative;
  background-color: var(--blue);
  color: #fff;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  transition: all .25s ease;
  /* font-family: var(--font-primary); */
  text-transform: uppercase;
  font-weight: 500;
}

img {
  filter: contrast(1.2); height: auto;
}

.page-content {
  padding: 5px 0 0 60px !important;
}

.container2{
  padding: 0px 0 0 0px !important;
}


.css-zgmaxs .chakra-link {display: block;}
.css-zgmaxs .chakra-link .linktext{display: none !important;}
.chakra-link svg{    margin: 0px 0 0 0;
  color: #8796a1;
  display: inline-block !important;
  position: relative;
  top: 2px;}
  .chakra-link:hover svg{color: #000;}
.chakra-link .linktext{margin-left:8px;}

.css-1cty7bx .linktext{display: none;}
.chakra-link div.linktext{display: inline-block !important;}

.main-tabs,
.mobile-block {
  border: none !important;
}

hr.chakra-divider{
opacity: 1;
/* border-color: #000;
border-style: solid; */
width: 100%;
/* width: 100%;
background: #000; */
}


.overviewtabs-sec {
  position: sticky; /* Make the navbar fixed */
  top:60px; /* Initially hide the navbar */
  left:0px;
  margin: auto;
  width:calc(100% - 0px);
  background-color: #fff; z-index: 20;
  transition: top 0.3s; /* Smooth transition when showing/hiding */
  display:none !important;
  border-bottom: 1px solid #d9e2e8;
}

.overviewtabs-sec:before {
    content: "";
    width:30px;
    background-color: #ffffff;
    position: absolute;
    height: 100%;
    left:-30px;
    z-index: 0;
    border-bottom: 1px solid #d9e2e8;
}

.overviewtabs-sec:after {
  content: "";
  width:30px;
  background-color: #ffffff;
  position: absolute;
  height: 100%;
  right:-30px;
  z-index: 0;
  border-bottom: 1px solid #d9e2e8;
}


.overviewtabs-sec.visible {
  display:flex !important;
}

.overviewtabs-sec ul{ list-style: none;}
.overviewtabs-sec ul li{ display: inline-block; }
.overviewtabs-sec ul li a{font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-feature-settings: 'liga' 0;
  padding:20px 15px;
  color: #5a6872;
  border-bottom: 3px solid transparent;
  border-top: 3px solid transparent; cursor: pointer; display: block;}

  .overviewtabs-sec ul li a:hover,
  .overviewtabs-sec ul li a.current {
    color: #212a30;
    border-bottom: 3px solid #000;
}

.address-map-section .vline{padding: 0 12px; opacity: 0.2;}

.css-8ecfaq {
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  line-height: 1.25rem;
  border-radius: var(--chakra-radii-lg);
  font-weight: var(--chakra-fontWeights-semibold);
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  height: var(--chakra-sizes-10);
  min-width: var(--chakra-sizes-10);
  font-size: var(--chakra-fontSizes-sm);
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  border-width: 1px;
  border-color: '#cbd5e0';
  flex-shrink: 0;
  background: var(--button-bg);
  color: #2d3748;
  --button-color: #2d3748;
  -webkit-box-flex: 1;
  flex-grow: 1;
}
.square-box {
  cursor: pointer;
  margin: 5px;
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: 3px;
  border: 1px solid #ccc;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.square-box.selected {
  background-color: #007bff; /* Blue color */
}

.success-message {
  color: #67aa3b;
  text-align: center;
}

.error-message {
  color: #ff0000;
  text-align: center;
}

.user-dashboard-sec h3.heading{ 
  font-size:40px; 
  font-weight: 700;  
  font-family: var(--chakra-fonts-heading); 
  margin: 10px 0 20px 0;
}
.user-dashboard-sec .top-sec  p { margin-bottom: 15px; }
.user-dashboard-sec .top-sec  p span{ display: block;  font-weight:700; }
.user-dashboard-sec .top-sec .text-right {
  text-align: right;
}

.user-dashboard-sec  .chakra-tabs {    padding: 15px 0 30px 0;}
.user-dashboard-sec  .chakra-tabs .chakra-tabs__tab{ font-size: 16px; font-weight: 600; }
.user-dashboard-sec  .chakra-tabs .chakra-tabs__tab-panels{     padding: 20px;
  border: 1px solid #ccc;
  margin: 15px 0;
  border-radius: 10px;}

  .user-dashboard-sec  .chakra-tabs .chakra-tabs__tab-panels h3 {
    font-weight: 600;
    font-size: 21px;
    color: #2b6cb0;
    margin-bottom: 10px;
}

.overlaysignin {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.overlay-content {
  position: relative;
  z-index: 1001; /* Ensure content appears above the overlay */
}

.sign-in-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; /* Ensure it's above the overlay */
  /* Add additional styles to center and style your sign-in page */
}

.arrow-steps-box {
  display: none; /* Hide the element by default */
}

.groupname{
background: #3182ce;
color: #fff;
font-weight: bold;
font-size: 16px;
}

.groupname:hover {
  color: black !important;  /* Keep the color unchanged */
  text-decoration: none !important;  /* Remove underline if it was added on hover */
  /* Remove any other hover effects */
}

.swal-popup-z-index {
  z-index: 11000 !important;
}

.swal2-container {
  z-index: 11000 !important; /* Adjust the z-index as needed */
}


.custom-carousel-container {
  max-width: 100%;
  overflow: hidden;
}

.carousel .carousel-slide {
  width: 100%;
  border-radius: 30px;
  overflow: hidden;
  height: 100%; /* Set the height to 50% of the viewport height */
}

.carousel .carousel-image {
  width: 100%;
  height: 90vh;
  object-fit: cover;
  border-radius: 0;
}

.carousel .thumbs-wrapper {
  margin: 10px 0;
}

.carousel .thumb {
  border: 2px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  height: 100px; /* Set the height for the thumbnails */
}

.carousel .thumb img {
  height: 100%; /* Make the thumbnail image fit its container */
  object-fit: cover;
}

.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: hidden !important; /* Prevent scrolling */
}

.autocomplete-container {
  position: relative;
}

.searchbox {
  width: 100%;
  padding: 8px;
  font-size: 16px;
}

.autocomplete-list {
  position: absolute;
  z-index: 1000;
  width: 100%;
  background: white;
  border: 1px solid #ddd;
  list-style: none;
  margin: 0;
  padding: 0;
}

.autocomplete-list li {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.autocomplete-list li:hover {
  background-color: #f0f0f0;
}

@media only screen and (min-width: 768px) {
  .arrow-steps-box {
    display: block;
  }
}

@media only screen and (max-width:767px) {
  .css-1o886do { grid-template-columns: repeat(1, 1fr) !important;}
  .css-1ye2k0l { padding: 20px 0 20px 80px !important;}
  .css-994wz4,
  .propertyinfo-sec{ display:block !important; }
  .css-1c45vkq { display: block !important; width: 100% !important;}
  p.chakra-text.css-gs02yc{line-height: 28px !important;}
  .css-guss89 { position: relative !important; bottom: 0!important; right: 0 !important;}
  .overviewtabs-sec { position: relative !important; top: 0 !important;  left: 0px !important; margin: auto !important;display: block !important;}
  .overviewtabs-sec.visible { display: block !important;}
  .overviewtabs-sec:before {width:0px !important; left:0px !important;}
  .css-rltemf { display: block !important; padding: 0 0 0 15px !important;}
  .map-sec { width: 100%;}
  .overviewtabs-sec:after{ display:none !important }
  #compare-sec.css-84e1h9 .page-content{padding: 10px 0 0 20px !important;}	 	
  #compare-sec.css-84e1h9 .page-content .css-1o886do { grid-template-columns: repeat(1, 1fr) !important;}
  .css-1qjjhwe,
.chakra-stack.css-elv6aj,
.ais-InstantSearch.css-1qjjhwe,
.chakra-stack.css-1x1fq5c,
.css-w8mx6m,
.css-2xph3x,
.css-1sgivba,
.css-1x1fq5c,
.chakra-stack.css-1igwmid,
.chakra-container.css-j86est{display: block !important;}
.chakra-container.css-j86est{display: block !important; padding:0 !important}
.search-3block-sec.css-kbg14{ width:100% !important;  display: block !important;}
.chakra-select__wrapper.css-1kkw2ov { margin: 15px 0 0 0 !important;}
.search-list-sec.css-gbcnz5,
.search-list-sec.css-1u1m7gk,
.chakra-container.css-5pe1lq { padding: 0 !important;}
.left-panel { float: none !important; width: 100% !important;}
.ais-Pagination { overflow-y: hidden !important; padding: 0 0 10px 0  !important;}

.css-1x1fq5c div{ width: 100% !important; }
.chakra-modal__content.css-x9dxnn{ width:92% !important; }
.chakra-stack.css-1sgivba{display: block !important;}
.chakra-stack.css-1sgivba button.chakra-button,
.chakra-stack.css-1sgivba button.chakra-button{ width: 100% !important;}
.chakra-stack.css-1sgivba button.chakra-button:first-child{ margin:0 0 10px 0 !important;}

.css-x9dxnn {display: block; height: 80% !important; overflow: scroll !important; margin: auto !important;}
.css-1lxizxv{top: 0 !important; right: 0 !important;}
button.chakra-button.css-jozgqo{ margin:10px 0  !important; }

.css-1x1fq5c div iframe { width: 100% !important;}

}
